.bg-slide3 {
  display: list-item;
  background-image: url(../../assets/staff-bg.png);
  background-color: #0f0909;
}

.staff-desc {
  font-family: "inter";
  font-size: 21px;
  text-align: justify;
}

.staff-header {
  font-family: "inter";
  font-size: 30px;
  font-weight: 600;
}

.service-header {
  color: #30007e;
  font-weight: 600;
  font-size: 15px;
  font-family: "inter";
  padding-bottom: 10px;
}

.service-desc {
  font-size: 15px;
  font-family: "inter";
  text-align: left;
}


@media (max-width: 680px) {

  .staff-desc {
    font-family: "inter";
    font-size: 17px;
    text-align: justify;
    padding-top: 30px;
  }

}