.doc-btn {
  color: #214fd6;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.attachment{
  border: 1px solid #DCDEDF;
  border-radius: 10px;
  padding: 20px;
}
.doc-name{
  color: #C6C5CA;
  font-size: 14px;
  
}