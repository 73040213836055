.background-section {
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: cover;
  padding: 0;
}

.bg-slider {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.bg-slide {
  display: list-item;
  // background-image: url(../../assets/bg.jpeg);
  background-color: #0f0909;
  transition: opacity 1s ease-in-out;
  opacity: 2;
  background-size: cover;
}

.bg-slide.fade-out {
  opacity: 0.8;
}

.bg-slide2 {
  display: list-item;
  background-image: url(../../assets/contact-bg.png);
  background-color: #0f0909;
}

.bg-container {
  height: 560px;
  display: table;
  table-layout: fixed;
  width: 100%;
  z-index: 2;
  position: relative;
  margin: 0 auto;
}

.bg-slide-container {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

.bg-slide-description {
  padding: 16% 8% 10% 8%;
  height: calc(100vh - 80px);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  animation-duration: 0.7s;
  animation-delay: 0.9s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fadeBottom;
  word-wrap: break-word;
  display: flex;
  align-items: end;
  justify-content: center;
}

.bg-slide-description3 {
  padding: 10% 8% 10% 8%;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  animation-duration: 0.7s;
  animation-delay: 0.9s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fadeBottom;
  word-wrap: break-word;
  display: flex;
  align-items: end;
  justify-content: start;
}

@keyframes fadeBottom {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-slide-content {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  font-family: "aleo";
}

.bg-slide-content2 {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  font-family: "aleo";
}

.bg-slide-content2 h2 {
  font-size: 38px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-family: "inter";
}

.bg-slide-content h2 {
  font-size: 38px;
  line-height: 1.2em;
}

.bg-slide-content p {
  font-style: italic;
}

.bg-slide-content2 p {
  font-family: "inter";
  font-size: 20px;
  padding-top: 15px;
}

.after-background-section {
  background: rgb(73, 70, 70);
  background: linear-gradient(
    180deg,
    rgba(73, 70, 70, 1) 0%,
    rgba(151, 114, 181, 1) 24%
  );
  padding-top: 10px;
}
.img-border {
  border-radius: 50%;
  width: 75px;
  background: rgb(151, 114, 181) !important;
}

.after-section-heading {
  font-size: 25px;
  font-family: "inter";
  line-height: 35px;
  font-weight: bold;
  color: #fff;
  margin: 0;
  position: relative;
  /* padding-left: 90px; */
  padding-bottom: 0;
}

.circle {
  background-color: white;
  border-radius: 50%;
}

.after-section-description {
  line-height: 18px;
  color: #ffffff;
  padding-top: 10px;
  font-family: "inter";
}

.contact-us {
  // margin-left: 50px;
  padding: 10px 20px 10px 20px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  font-weight: 500;
}

.who-we-are-img {
  border-radius: 10px;
}

.who-we-are-heading {
  padding-top: 10px;
  font-family: "jost";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 18px;
  color: #051b13;
}

.who-we-are-desc {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 36px;
  color: #000000;
  text-align: justify;
}

.care {
  width: 75%;
  border-radius: 10px;
}

.why-choose-header {
  font-family: "jost";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #051b13;
  margin-bottom: 0;
}

.why-choose-desc {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #33373e;
  margin-top: 33px;
  margin-bottom: 0;
  text-align: justify;
}

.choose-btn {
  color: #fff;
  display: inline-block;
  padding: 0 30px;
  vertical-align: middle;

  line-height: 60px;
  height: 60px;
  background-color: #5555c2;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 13px;
  position: relative;
  border-radius: 25px;
  text-decoration: none;
}
.fifthsec__banner {
  background: #0f051b;
  border-radius: 10px;
  padding: 39px;
  position: relative;
}

.fifthsec__banner_vector {
  position: absolute;
  right: 0;
  bottom: 0;
}

.fifthsec__banner_vector img {
  border-bottom-right-radius: 10px;
}

.fifthsec__banner_heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
}

.fifthsec__banner_paragraph {
  font-size: 16px;
  line-height: 35px;
  color: #ffffff;
}

.fifthsec__banner_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #8618c6;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 40px;
  border: none;
  padding: 12px 17px 12px 17px;
  color: #fff;
}

.image_bac {
  background-image: url("../../assets/services.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.secondsec__heading_small_text {
  font-weight: 400;
  font-size: 20px;
  /* padding: 10px 0; */
  color: #ffffff;
  line-height: 40px;
}
.secondsec__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  /* padding: 10px 0px; */
  color: #ffffff;
}

.secondsec__step {
  display: flex;
  margin: 29px 0;
}

.secondsec__paragraph_text {
  margin: 10px 20px;
  display: flex;
  color: #2c3e59;
  font-weight: 600;
  flex-direction: column;
  color: #ffffff;
  font-size: 20px;
}

.secondsec__paragraph_text--sub {
  color: #ffffff;
  font-weight: normal;
  font-size: 20px;
  font-weight: 400;
}

.right_setup {
  background: linear-gradient(
    269.65deg,
    #2d1449 9.98%,
    rgba(249, 249, 249, 0) 595.87%
  );
  height: 100%;
  padding: 40px 50px !important;
}
@media (min-width: 981px) {
  .bg-slide {
    background-size: cover;
    animation: zoom-in-zoom-out 40s ease-out infinite;
    list-style: none !important;
    background-position: 50%;
    float: left;
    margin-right: -100%;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .bg-slide2 {
    background-size: cover;
    animation: zoom-in-zoom-out 40s ease-out infinite;
    list-style: none !important;
    background-position: 50%;
    float: left;
    margin-right: -100%;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .bg-slide3 {
    background-size: cover;
    animation: zoom-in-zoom-out 40s ease-out infinite;
    list-style: none !important;
    background-position: 50%;
    float: left;
    margin-right: -100%;
    position: relative;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 680px) {
  .bg-slide-description {
    padding: 36% 8% 18% 8% !important;
    height: calc(100vh - 20vh);
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    animation-duration: 0.7s;
    animation-delay: 0.9s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-name: fadeBottom;
    word-wrap: break-word;
  }

  .bg-slide-description3 {
    padding: 36% 8% 18% 8% !important;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    animation-duration: 0.7s;
    animation-delay: 0.9s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-name: fadeBottom;
    word-wrap: break-word;
  }

  .after-section-description {
    padding-bottom: 20px;
    line-height: 25px;
  }
}

@media (max-width: 480px) {
  .why-choose-desc {
    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    color: #33373e;
    margin-top: 13px;
    margin-bottom: 0;
    text-align: justify;
  }

  .who-we-are-desc {
    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 28px;
    color: #000000;
    text-align: justify;
  }
}

// .item-banner {
//   height: 70vh;
//   position: relative;
// }

// .img{
//   height:  600px;
// }




@media only screen and (max-width: 600px) {
  .item-banner {
    padding-top: 70px;
    height: 300px;
  }

  .img{
    height: 300px;
  }

  .bg-slide-content {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    font-family: "aleo";
  }

  .bg-slide-content h2 {
    font-size: 22px;
    line-height: 1.2em;
  }


  .who-we-are-heading {
    padding-top: 10px;
    font-family: "jost";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 18px;
    color: #051b13;
  }

  .why-choose-header {
    font-family: "jost";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    color: #051b13;
    margin-bottom: 0;
  }

  .secondsec__heading {
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    /* padding: 10px 0px; */
    color: #ffffff;
  }

  .secondsec__heading_small_text {
    font-weight: 400;
    font-size: 18px;
    /* padding: 10px 0; */
    color: #ffffff;
    line-height: 40px;
  }
  
}





.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}


@media only screen and (min-width: 991px) {
  .item-banner {
    padding-top: 70px;
    height: 600px;
  }

  .img{
    height: 600px;
  }
}

@media only screen and (min-width: 1600px) {
  .item-banner {
    padding-top: 70px;
    height: 80vh;
  }

  .img{
    height: 80vh;
  }
}