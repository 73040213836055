body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Ponnala";
  src: url("./fonts/Ponnala.ttf.woff") format("woff"),
    url("./fonts/Ponnala.ttf.svg#Ponnala") format("svg"),
    url("./fonts/Ponnala.ttf.eot"),
    url("./fonts/Ponnala.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap");

.scroll-element {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease-out;
}

.scroll-element.visible {
  opacity: 1;
  transform: translateX(0);
}

.scroll-element2 {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s ease-out;
}

.scroll-element2.visible {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 1037px) {
  .scroll-element {
    opacity: 1;
    transform: translateX(0);
    transition: none;
  }
  .scroll-element2 {
    opacity: 1;
    transform: translateX(0);
    transition: none;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}
