.collapse-bg1{
    background-color: #420059 !important;
    color: white !important;
}

.collapse-bg2{
    background-color: #7B36A5 !important;
    color: white !important;
}


.collapse-bg3{
    background-color: #0F0682 !important;
    color: white !important;
}

.plus-ico{
    color: #F8A02C !important;
    font-weight: 800 !important;
}

.service-title{
    font-family: 'jost';
    font-size: 21px;
}

.card-body{
    font-family: 'inter' !important;
    text-align: justify;
}


  