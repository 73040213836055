.top-header {
  width: 100%;
  position: relative;
}
.header {
  z-index: 9999;
  // background: rgb(221, 220, 220);
  // background: linear-gradient(
  //   360deg,
  //   rgba(221, 220, 220, 1) 3%,
  //   rgba(255, 255, 255, 1) 27%
  // );
  background-color: white;
  width: 100%;
  // padding-bottom: 10px;
  // padding-top: 10px;
  justify-content: space-between;
}

.shadow {
  border-radius: 2px;
  box-shadow: 0px -1px 7px 0px #999;
}
.logo-container {
  font-weight: 700;
  font-size: 32px;
  color: rgb(255, 81, 0);
}

.header-info {
  font-family: Montserrat, sans-serif;
  color: "#636363";
  font-weight: 500;
  line-height: 21px;
  font-size: 15px;
}

.header-content {
  font-family: "Nunito", sans-serif;
  color: "#1c232f";
  font-weight: 800;
  line-height: 25px;
  font-size: 17px;
}
.quote-btn {
  color: #fff;
  display: inline-block;
  padding: 0 30px;
  vertical-align: middle;

  line-height: 60px;
  height: 60px;
  background-color: #4242a5;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 13px;
  position: relative;
  border-radius: 6px;
  text-decoration: none;
}

// bottom header

.bottom-header {
  background-color: #1c232f;
  color: white;
}

.bottom-header-container {
  display: table;
  vertical-align: middle;
  z-index: 99;
}

.menu {
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
  margin-right: 52px;
}


.menu2 {
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid black;
  padding: 12px;
}


.active {
  color: #4242a5;
}

.hide-mobile {
  display: flex;
}

.hide-mobile2 {
  display: flex;
}

// .mobile-header {
//   background-color: #4242a5;
//   z-index: 999999;
//   position: fixed;
//   width: 100%;
//   top: 100px;
//   color: white;
//   padding-top: 10px;
//   padding-bottom: 10px;
// }

.mobile-header {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99999;
}

.view-mobile {
  display: none;
}

.logo-border {
  border-radius: 10px;
  margin-right: 10px;
  width: 140px;
  height: 100px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100000;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
}

.hamburger .line {
  height: 3px !important;
  margin: 4px 0px 4px auto !important;
  border-radius: 5px;
  background-color: var(--gray-color);
  transition: all 0.5s;
  width: 35px !important;
}

.hide-btn {
  display: block !important;
}
@media (max-width: 1200px) {
  .hide-mobile {
    display: none !important;
  }
  .view-mobile {
    display: flex !important;
  }
}

@media (max-width: 1360px) {
  .hide-btn {
    display: none !important;
  }
}

@media (max-width: 680px) {
  .hide-mobile2 {
    display: none !important;
  }

  .logo-container {
    font-weight: 700;
    font-size: 32px;
    color: rgb(255, 81, 0);
    border-right: none;
  }

  .header {
    justify-content: center;
  }

  .logo-border {
    width: 120px;
    height: 80px;
  }
}

.sidebar {
  background: #fff;
  border-right: 1px solid #ddd;
  padding: 20px;
  box-sizing: border-box;
  // max-width: 16rem;
  margin-top: 90px;
  margin-left: 0px;
  border-radius: 10px;
  position: fixed;
  height: 30vh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 99999;
  width: 100%;
}

.sidebar-open {
  transform: translateX(0);
}
