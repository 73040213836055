.contactus-section {
  width: 100%;
  /* height: 800px; */
  /* background: url(../assets/SVG/vertical-lines.svg), #F0F9F7; */
  background-color: #f0f0f9;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 0px 70px;
}

.contactus-row {
  padding: 90px 0px;
}

.contactus-details {
  padding: 52px 0;
}
.contactus-details__main-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 170%;
  color: #051b13;
}

.contactus-details__main-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 189.5%;
  color: #777777;
}

.contactus-info:not(:last-child) {
  border-right: 1px solid #c2c2c2;
}

.contactus-info__title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.footer-socialmedia {
  list-style: none;
  padding-left: 0px;
  display: flex;
  margin-bottom: 0px;
}

.footer-socialmedia__item:not(:last-child) {
  margin-right: 15px;
}

// .contactus-info .footer-socialmedia__item-link {
//   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
// }

.footer-socialmedia__item-link {
  // width: 34px;
  // height: 34px;
   padding: 10px 17px;
  // background-color: #ffffff;
  // border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactus-info:not(:last-child) {
  border-right: 1px solid #c2c2c2;
}

.contactus-info__details {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.contactus-info__details-img {
  width: 30px;
  height: 30px;
  margin-right: 25px;
}

.contactus-info__details-img {
  width: 30px;
  height: 30px;
  margin-right: 25px;
}

.contactus-info__details-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  text-decoration: none;
}

.form-header {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: normal;
  color: #051b13;
  margin-bottom: 35px;
}

.contactus-form-input {
  height: 58px;
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 9px;
}

.contact-btn {
  padding: 16px 60px;
  border-radius: 7px;
  background-color: #4242A5;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  transition: all 0.5s;
}

.contactus-form-textarea {
  height: 208px;
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 9px;
}
textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
.contactus-form-label {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #4f4f4f;
  margin-bottom: 6px;
}

.form-required-sign {
  color: var(--bs-danger);
  margin: 0 3px;
}
.middle-tel {
  justify-content: center;
}

.image-container {
  position: relative;
  width: 100%; /* Adjust as needed */
}

.img-fluid2 {
  width: 100%;
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 80%; /* Adjust as needed */
  left: 20%; /* Adjust as needed */
  transform: translate(-50%, -50%);
  color: white; /* Adjust as needed */
  font-family: 'inter';
  font-size: 24px; /* Adjust as needed */
  font-weight: 400; /* Adjust as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: for better readability */
  line-height: 36px;
}

.contact-end{
  font-family: 'Kalam';
  font-size: 25px;
  padding-top: 45px;
  line-height: 50px;
}

@media (max-width: 680px) {
  .middle-tel {
    justify-content: start;
  }

  .overlay-text {
    width: 80%;
    position: absolute;
    top: 80%; /* Adjust as needed */
    left: 50%; /* Adjust as needed */
    transform: translate(-50%, -50%);
    color: white; /* Adjust as needed */
    font-size: 22px; /* Adjust as needed */
    font-weight: bold; /* Adjust as needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: for better readability */
  }

  .img-fluid2 {
    width: 100%;
    height: 380px;
  }

  .image-container {
    position: relative;
    width: 100%; /* Adjust as needed */
    padding-top: 80px;
  }
}

.loader {
  display: inline-block;
  width: 16px; // Adjust size as needed
  height: 16px; // Adjust size as needed
  border: 3px solid rgba(255, 255, 255, 0.3); // Light grey border
  border-radius: 50%;
  border-top-color: #fff; // White border top color
  animation: spin 1s ease-in-out infinite; // Animation for spinning effect
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}