.footer {
  background-color: #05061b;
}

.footer__top {
  padding: 79px 0px 111px;
}

.footer__top-section {
  margin-bottom: 50px;
}
@media only screen and (min-width: 854px) {
  .footer__top-section {
    margin-bottom: 0px;
  }
}

.footer__logo {
  padding: 0 0 30px;
}

.footer__paragraph {
  color: #cfcfcf;
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
  text-align: justify;
  padding-right: 20px;
  font-family: 'inter';
}

.footer__links_heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
  padding: 0 0 30px;
}

.footer_a_tag {
  text-decoration: none;
  font-family: "inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  color: #cfcfcf;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.footer-socialmedia {
  list-style: none;
  padding-left: 0px;
  display: flex;
  margin-bottom: 0px;
}

.footer-socialmedia__item:not(:last-child) {
  margin-right: 15px;
}

.footer-socialmedia__item-link {
  width: 34px;
  height: 34px;
   padding: 10px 17px;
  // background-color: #ffffff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__copyrigh_paragraph {
  font-size: 14px;
  line-height: 10px;
  color: #fff;
  text-align: center;
  padding: 37px 0px;
  margin-bottom: 0;
}
.footer__horizontal_line {
  border-top: 1px solid #DFA245;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
