.image-with-shadow {
  display: inline-block;
  filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5));
  padding-top: 10px;
  height: 400px;
  border-radius: 10px;
}

.about-us-top {
  padding: 30px;
  padding-top: 100px;
  background: url("../../assets//about-bg1.png");

  padding-bottom: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us-bottom {
  padding-top: 30px;
  padding-top: 200px;
  background: url("../../assets//about-bg2.png");


  background-repeat: no-repeat;
  background-size: cover;
}


.about-us-top2 {
  // padding: 30px;

  background: url("../../assets//about-bg3.png");

  padding-bottom: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}

.founder-name {
  font-size: 36px;
  font-weight: 600;
  font-family: "inter";
  color: #2400b2;
}

.founder {
  color: #4b30b5;
  font-size: 26px;
  font-weight: 600;
  font-family: "inter";
}

.founder-desc {
  text-align: justify;
  letter-spacing: 2%;
  font-size: 20px;
  font-weight: 400;
  font-family: "inter";
  line-height: 30px;
  color: rgb(58, 57, 57);
  padding-top: 20px;
}
.quote {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #8db5e8;
}

.mission-header {
  font-size: 48px;
  font-family: "jost";
  font-weight: 500;
  text-align: center;
  padding: 50px;
}

.mission-description {
  font-size: 24px;
  font-family: "inter";
  font-weight: 300;
  text-align: justify;
  line-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
}

.mission-padding {
  padding: 20px;
}


.emp1-heading{
  color: #DB7907;
  font-family: 'inter';
  font-size: 25px;
  font-weight: 600;
}

.emp1-heading2{
  color: #F49508;
  font-family: 'inter';
  font-size: 20px;
  font-weight: 600;
}


.emp2-heading{
  color: #2400B2;
  font-family: 'inter';
  font-size: 25px;
  font-weight: 600;
}

.emp2-heading2{
  color: #4B30B5;
  font-family: 'inter';
  font-size: 20px;
  font-weight: 600;
}

.emp-description{
  font-size: 19px;
  line-height: 34px;
  font-family: 'inter';
  text-align: justify;
}

@media (max-width: 680px) {
  .image-with-shadow {
    display: inline-block;
    filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5));

    padding-top: 50px;
    padding-bottom: 30px;
    height: 50vh;
  }

  .top-padding {
    padding-top: 80px;
  }

  .about-us-top {
    padding: 30px;
    padding-top: 0;
    background: url("../../assets//about-bg1.png");

    padding-bottom: 150px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .founder-desc {
    text-align: justify;
    font-size: 17px;
    font-weight: 400;
    font-family: "inter";
    line-height: 30px;
    color: rgb(58, 57, 57);
    padding-top: 20px;
    hyphens: auto;
    word-spacing: -0.05em;
  }

  .mission-description {
    font-size: 17px;
    font-family: "inter";
    font-weight: 300;
    text-align: justify;
    line-height: 30px;
    hyphens: auto;
    word-spacing: -0.07em;
  }


  .mission-header {
    font-size: 30px;
    font-family: "jost";
    font-weight: 500;
    text-align: center;
    padding: 40px;
  }

  .about-us-top2 {
    // padding: 30px;
  
    background: url("../../assets//about-bg3.png");
  
    padding-bottom: 100px;
    background-repeat: no-repeat;
    background-size: cover;



  }
  

  .emp-description{
    font-size: 17px;
    line-height: 30px;
    font-family: 'inter';
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.07em;
  }
}
